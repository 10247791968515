import { useQueryClient } from "@tanstack/react-query";
import { getWidgetValues } from "../../api/dashboardApi";
import ReloadIcon from "../../icons/ReloadIcon";
import DashBoardItems from "../../modules/DashBoardItems/DashBoardItems";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import DayPickerRangeSelect from "../../components/DatePicker/DataPresetPicker";
import { AuthContext } from "../../context/AuthContext";

const Dashboard = () => {
  const { isFirstLoadDashboard } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const storedRange = JSON.parse(sessionStorage.getItem("dashboardRange"));

  const [range, setRange] = useState({
    from: storedRange?.from ? new Date(storedRange.from) : new Date(),
    to: storedRange?.to ? new Date(storedRange.to) : new Date(),
  });

  const { data, isLoading, isFetching, refetch } = useAuthQuery({
    queryKey: ["dashboardValues"],
    queryFn: () => getWidgetValues(range),
    enabled: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: false,
  });

  const handleAcceptRange = (from, to) => {
    const newRange = { from, to };
    setRange(newRange);
    sessionStorage.setItem("dashboardRange", JSON.stringify(newRange));
    setTimeout(() => {
      refetch();
    }, 0);
  };

  const handleUpdateDashboardData = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["dashboardValues"],
    });
    refetch();
    queryClient.invalidateQueries({ queryKey: ["news"] });
    queryClient.invalidateQueries({
      queryKey: ["dataDashBoardTable"],
    });
  }, [queryClient]);

  useEffect(() => {
    if (isFirstLoadDashboard.current) {
      isFirstLoadDashboard.current = false;
      refetch();
    }
  }, []);

  return (
    <div className={styles.dashboardMain}>
      <div className={styles.wrapperHeaderDashboard}>
        <div className={styles.dashboardTitleContainer}>
          <h2 className={clsx("H1")}>Dashboard</h2>
          <ReloadIcon
            className={styles.reloadIcon}
            onClick={handleUpdateDashboardData}
            style={{
              animation: isFetching ? "spin 1s linear infinite" : "none",
            }}
          />
        </div>
        <div>
          <DateFormatter
            fullLongFormat={true}
            live={true}
            className="titleDateFormatter"
          />
        </div>
      </div>
      <div className={styles.wrapperDataPickerDashboard}>
        <DayPickerRangeSelect
          value={[range.from, range.to]}
          onAccept={handleAcceptRange}
        />
      </div>
      <div className={styles.centerMainContent}>
        <DashBoardItems
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </div>
    </div>
  );
};

export default Dashboard;
