import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import style from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

export const formatter = (
  date,
  withTime,
  onlyTime = false,
  userTimeZone,
  fullLongFormat = false
) => {
  if (!date) return "";

  let formattedDate = "";

  if (date) {
    const dateObj = parseISO(date);

    const zonedDate = toZonedTime(dateObj, userTimeZone);

    if (fullLongFormat) {
      // Monday, 24 March 2025, 16:37
      return format(zonedDate, "EEEE, dd MMMM yyyy, HH:mm");
    }

    if (onlyTime) {
      formattedDate = format(zonedDate, "HH:mm");
    } else {
      const formatTime = withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
      formattedDate = format(zonedDate, formatTime);
    }
  }
  return formattedDate;
};

export const DateFormatter = ({
  date,
  className,
  loading,
  withTime = true,
  onlyTime = false,
  fullLongFormat = false,
  live = false,
}) => {
  const { userTimeZone } = useContext(AuthContext);
  const [currentTime, setCurrentTime] = useState(
    date || new Date().toISOString()
  );

  useEffect(() => {
    if (!live) return;

    setCurrentTime(new Date().toISOString());

    // Задержка до начала следующей минуты
    const now = new Date();
    const msUntilNextMinute =
      60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    // Первый таймер до начала следующей минуты
    const timeout = setTimeout(() => {
      setCurrentTime(new Date().toISOString());

      // Интервал ровно каждую минуту
      const interval = setInterval(() => {
        setCurrentTime(new Date().toISOString());
      }, 60000);

      return () => clearInterval(interval);
    }, msUntilNextMinute);

    return () => clearTimeout(timeout);
  }, [live]);

  return (
    <p className={className}>
      {loading ? (
        <div className={style.textSkeleton}></div>
      ) : (
        formatter(currentTime, withTime, onlyTime, userTimeZone, fullLongFormat)
      )}
    </p>
  );
};
