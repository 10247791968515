import axios from "axios";
import eventEmitter from "../utils/emitter/EventEmitter";
const BASE_URL = "https://fbm-pay.com";

const waitForToken = (timeout = 5000) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const interval = setInterval(() => {
      const token = localStorage.getItem("token");
      if (token) {
        clearInterval(interval);
        resolve(token);
      } else if (Date.now() - startTime > timeout) {
        clearInterval(interval);
        reject(new Error("Token was not available in time"));
      }
    }, 50);
  });
};

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosPublic.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPublic.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 429) {
        eventEmitter.emit("uncorrectPin_logoutUser");
      }
    }
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.request.use(
  async (config) => {
    try {
      const token = await waitForToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

// axiosAuth.interceptors.request.use(
//   async (config) => {
//     let token = localStorage.getItem("token");

//     if (!token) {
//       await new Promise((resolve) => setTimeout(resolve, 10));
//       token = localStorage.getItem("token");

//       if (!token) {
//         return Promise.reject(new Error("No token available"));
//       }
//     }
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axiosAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = localStorage.getItem("token");

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      if (token) {
        eventEmitter.emit("showPinModal");
      }
    }

    return Promise.reject(error);
  }
);
