import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { getUserData, logoutUser, updateUser } from "../../api/userApi";
import { PAGE_ROUTES } from "../../pages/constants";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import TimezoneSelect from "react-timezone-select";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { customTimeZoneSelectStyles } from "../../styles/constants";
import { showToast } from "../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../constants/constants";
import ChangePinModal from "../Modals/ChangePinModal/ChangePinModal";
import { ListItemText, MenuItem, Select } from "@mui/material";

const ProfileSettings = () => {
  const { userTimeZone, setUserTimeZone } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [selectedTimeZone, setSelectedTimeZone] = useState(userTimeZone);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [isModalChangePinVisable, setIsModalChangePinVisable] = useState(false);
  const [selectedTwoFaType, setSelectedTwoFaType] = useState("");

  const { data, isLoading, isFetching, isError, error } = useAuthQuery({
    queryKey: ["userProfileSettings"],
    queryFn: getUserData,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
  });

  const navigate = useNavigate();
  const profileData = data?.data;

  const handleChangeTimeZone = useCallback(() => {
    if (userTimeZone === selectedTimeZone) {
      showToast("error", "You already have this time zone selected ");
      return;
    }

    setUserTimeZone(selectedTimeZone);
    localStorage.setItem("userTimeZone", selectedTimeZone);
    showToast(
      "success",
      `Your time zone has been successfully changed to ${selectedTimeZone}`
    );
  }, [selectedTimeZone, userTimeZone]);

  const handleChangeTwoFaType = useCallback(
    (e) => {
      const selectedValue = e.target.value;

      if (
        selectedValue === "telegram" &&
        !profileData.isTelegramVerificationEnabled
      ) {
        showToast(
          "error",
          "To receive codes in TG, enter your Telegram ID in the appropriate field."
        );
        return;
      }

      setSelectedTwoFaType(selectedValue);
    },
    [profileData]
  );

  const handleOpenChangePinModal = useCallback(() => {
    setIsModalChangePinVisable(true);
  }, []);

  const handleCloseChangePinModal = useCallback(() => {
    setIsModalChangePinVisable(false);
  }, []);

  const handleSaveTwoTypeChanges = async () => {
    try {
      const res = await updateUser(data.data.id, {
        "2fa_login_type": selectedTwoFaType,
      });

      if (res.status === 200) {
        queryClient.setQueryData(["userProfileSettings"], (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            data: {
              ...oldData.data,
              ["2fa_login_type"]: selectedTwoFaType,
            },
          };
        });

        showToast(
          "success",
          "The method of obtaining 2FA codes has been successfully changed."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      showToast(
        "error",
        error.response?.data?.data?.message || "Unexpected server error"
      );
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      queryClient.invalidateQueries(["userProfileSettings"]);
      navigate(PAGE_ROUTES.LOGIN);
      showToast("success", NOTIFICATION_INFO.LOGOUT);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const incomingType = data?.data?.["2fa_login_type"];

    if (
      incomingType &&
      twoFaSelectTypes.some((el) => el.id === incomingType.toLowerCase())
    ) {
      setSelectedTwoFaType(incomingType.toLowerCase());
    }
  }, [data]);

  if (isError) {
    return <div>{error.message}</div>;
  }
  const twoFaSelectTypes = [
    { id: "telegram", label: "Telegram" },
    { id: "email", label: "E-mail" },
  ];

  return (
    <>
      <div className={styles.profileSettigns}>
        <div>
          {/* <div className={clsx(styles.flexRow)}>
            <h2>Status</h2>
            <span className={styles.statusIsActive}>Active</span>
          </div> */}
          <div className={clsx(styles.flexRow)}>
            <h2>Registration date</h2>
            <span>
              <DateFormatter
                date={profileData?.registred_at}
                loading={isLoading}
                className={styles.labelsFont}
              />
            </span>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>Name</h2>
            <div className={styles.profileChangeContainer}>
              {isLoading || isFetching ? (
                <div className={styles.textSkeleton}></div>
              ) : (
                <span className={styles.labelsFont}>{profileData?.name}</span>
              )}
              <Link
                className={styles.changeProfileButtons}
                to={PAGE_ROUTES.PROFILE_CHANGE}
              >
                Change
              </Link>
            </div>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>E-mail</h2>
            {isLoading || isFetching ? (
              <div className={styles.profileChangeContainer}>
                <div className={styles.textSkeleton}></div>
              </div>
            ) : (
              <span className={styles.labelsFont}>{profileData?.email}</span>
            )}
          </div>

          <div className={clsx(styles.flexRow)}>
            <h2>Telegram ID</h2>
            <div className={styles.profileChangeContainer}>
              {isLoading || isFetching ? (
                <div className={styles.textSkeleton}></div>
              ) : (
                <span className={styles.labelsFont}>
                  {profileData?.telegram}
                </span>
              )}

              <Link
                className={styles.changeProfileButtons}
                to={PAGE_ROUTES.PROFILE_CHANGE}
              >
                Change
              </Link>
            </div>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>2FA codes</h2>

            <div className={styles.profileChangeContainer}>
              <Select
                labelId="twoFAType-select-label"
                id="twoFAType-select"
                value={selectedTwoFaType}
                label="Select the account"
                key={selectedTwoFaType}
                onChange={handleChangeTwoFaType}
                displayEmpty
                renderValue={(selected) => {
                  const selectedOption = twoFaSelectTypes.find(
                    (el) => el.id === selected
                  );
                  return selectedOption
                    ? selectedOption.label
                    : "Select 2FA method";
                }}
                sx={{
                  width: "150px",
                  border: "1px solid",
                  borderColor: "#AAAAAA",
                  borderRadius: "8px",
                  height: "36px",
                  color: "rgb(70, 70, 70)",

                  "&:hover": {
                    borderColor: "#21a038",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSelect-select": {
                    padding: "8px",
                  },
                }}
              >
                {twoFaSelectTypes.map((el) => {
                  return (
                    <MenuItem
                      key={el.id}
                      value={el.id}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        "&:hover": {
                          backgroundColor: "#21a038",
                          color: "white",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#21a038",
                          color: "white",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#21a038",
                          color: "white",
                        },
                        "&.Mui-selected.Mui-focusVisible": {
                          backgroundColor: "#21a038",
                          color: "white",
                        },
                      }}
                    >
                      <ListItemText
                        primary={el.label}
                        style={{ fontSize: "12px" }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>

              <Button
                onClick={handleSaveTwoTypeChanges}
                className={styles.changeProfileButtons}
              >
                Save
              </Button>
            </div>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>Password</h2>
            <div className={styles.profileChangeContainer}>
              <span>******</span>
              <Link
                className={styles.changeProfileButtons}
                to={PAGE_ROUTES.PROFILE_PASSWORD_CHANGE}
              >
                Change
              </Link>
            </div>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>PIN code</h2>
            <div className={styles.profileChangeContainer}>
              <Button
                className={styles.changeProfileButtons}
                onClick={handleOpenChangePinModal}
                style={{ marginLeft: "0px" }}
              >
                Change
              </Button>
            </div>
          </div>
          <div className={clsx(styles.flexRow)}>
            <h2>Time zone </h2>
            <div className={styles.profileTimeZoneContainer}>
              <TimezoneSelect
                value={selectedTimeZone}
                onChange={(value) => setSelectedTimeZone(value.value)}
                menuIsOpen={menuIsOpen}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                closeMenuOnScroll={false}
                styles={customTimeZoneSelectStyles}
                isSearchable={false}
                components={{
                  DropdownIndicator: VisualDropdownIndicator,
                }}
              />

              <Button
                className={styles.changeProfileButtons}
                onClick={handleChangeTimeZone}
              >
                Save
              </Button>
            </div>
          </div>

          {/* <div className={clsx(styles.flexRow, styles.flexRowMobile)}>
            <div>
              <h2>Two-factor authentication*</h2>
              <span className={styles.twoFaSpan}>
                We do not allow you to turn it off for security reasons.
              </span>
            </div>
            <div>
              <TwoFaIcon />
            </div>
          </div> */}
          <div className={clsx(styles.flexRow, styles.flexRowMobile)}>
            <h2>Logout from account</h2>

            <Button
              onClick={handleLogout}
              className={styles.profileChangeLogout}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>

      {isModalChangePinVisable && (
        <ChangePinModal onCLose={handleCloseChangePinModal} />
      )}
    </>
  );
};

export default ProfileSettings;

const VisualDropdownIndicator = (props) => {
  const { selectProps } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        cursor: "default",
      }}
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2L5 7L10 2H0Z"
          fill={selectProps.menuIsOpen ? "#232323" : "#AAAAAA"}
        />
      </svg>
    </div>
  );
};
