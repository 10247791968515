const ArrowTop = () => {
  return (
    <>
      <svg
        width="10"
        height="10"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.84085 1.77455C3.35168 1.77454 2.95512 1.37799 2.95512 0.888818C2.95511 0.399648 3.35165 0.0031064 3.84082 0.00311387L12.8367 0.00312141C14.5838 0.00312849 16 1.41937 16 3.16644L16 12.1623C16 12.6515 15.6035 13.048 15.1143 13.048C14.6252 13.048 14.2286 12.6515 14.2286 12.1623L14.2286 3.16644C14.2286 3.12156 14.2265 3.07725 14.2223 3.03345L2.1856 15.0702C1.8397 15.4161 1.27885 15.4161 0.932986 15.0702C0.587121 14.7243 0.587085 14.1635 0.932985 13.8176L12.9697 1.78084C12.9259 1.77668 12.8816 1.77461 12.8367 1.77454L3.84085 1.77455Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ArrowTop;
