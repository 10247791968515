import React from "react";
import styles from "./styles.module.scss";
import {
  DateFormatter,
  formatter,
} from "../../components/DateFormatter/DateFormatter";
import { getCardStatus } from "../../utils/getCardStatus";
import { widthStatusTitle } from "../../styles/constants";
import clsx from "clsx";

const ColumnsCardHistory = () => {
  const columns = [
    {
      accessorKey: "formattedDate",
      header: "DATE",
      enableSorting: false,
      accessorFn: (row) => formatter(row.dateTime, false),
      Cell: ({ row }) => {
        const date = row.original.dateTime;

        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <DateFormatter date={date} withTime={true} />
          </div>
        );
      },
      size: 100,
    },

    {
      accessorKey: "merchantAmount",
      header: "TRANSACTION AMOUNT",
      enableSorting: false,

      accessorFn: (row) => `${row.merchantAmount} ${row.merchantCurrency}`,
      Cell: ({ row }) => {
        const amaount = row.original.merchantAmount;
        const amaountCurrency = row.original.merchantCurrency;
        const statusTransaction = row.original.type === "Credit";

        return (
          <div
            className={clsx(styles.statusCard, styles.defaultTableFontSize)}
            style={{ color: statusTransaction ? "#21A038" : "black" }}
          >
            {statusTransaction ? "+" : "-"}
            {amaount} {amaountCurrency}
          </div>
        );
      },
    },

    {
      accessorKey: "accountAmount",
      header: "ACCOUNT AMOUNT",
      enableSorting: false,

      accessorFn: (row) => `${row.accountAmount} ${row.accountCurrency}`,
      Cell: ({ row }) => {
        const amaount = row.original.accountAmount;
        const statusTransaction = row.original.type === "Credit";

        return (
          <div
            className={clsx(styles.statusCard, styles.defaultTableFontSize)}
            style={{ color: statusTransaction ? "#21A038" : "black" }}
          >
            {" "}
            {statusTransaction ? "+" : "-"}
            {amaount} €
          </div>
        );
      },
    },
    {
      accessorKey: "status",
      enableSorting: false,
      header: "STATUS",
      accessorFn: (row) => {
        const statusCode = row.status;

        const { statusCard } = getCardStatus(statusCode);

        return statusCard;
      },
      Cell: ({ row }) => {
        const statusCode = row.original.status;
        const cardStateCode = row.original.stateCode === "50";

        const { statusCard, colorContainer, colorText } = getCardStatus(
          statusCode || cardStateCode
        );

        return (
          <div
            className={clsx(styles.statusCard)}
            style={{
              background: colorContainer,
              color: colorText,
              width: widthStatusTitle,
              textAlign: "center",
            }}
          >
            {statusCard}
          </div>
        );
      },
    },
    {
      accessorKey: "details",
      header: "PAYMENT DETAILS",
      enableSorting: false,
    },
    {
      accessorKey: "balance",
      header: "BALANCE",
      enableSorting: false,
      accessorFn: (row) => `${row.balance} ${row.balanceCurrency}`,
      Cell: ({ row }) => {
        const amaount = row.original.balance;

        return (
          <div className={clsx(styles.statusCard, styles.defaultTableFontSize)}>
            {amaount} €
          </div>
        );
      },
    },
    {
      accessorKey: "accountIban",
      header: "ACCOUNT",
      enableSorting: false,
      accessorFn: (row) => row.accountIban.slice(-6),
      Cell: ({ row }) => {
        const name = row.original.accountIban.slice(-6);
        return (
          <div className={styles.ibanAcc}>
            <span>{name}</span>
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              {row.original.accountName}
            </span>
          </div>
        );
      },
    },
  ];

  return columns;
};

export default ColumnsCardHistory;
