import React from "react";
import styles from "./styles.module.scss";
import SimpleAreaChart from "../SimpleAreaChart/SimpleAreaChart";
import spendingChart from "../../images/ChartSpending.svg";

const SpendingChart = ({ spendChartDataset }) => {
  return (
    <div className={styles.mainWrapperSpendingChart}>
      <div className={styles.containerSpendingChart}>
        <div className={styles.containerTitleSpendingChart}>
          <span className="H3">ACTIVITY</span>
          <img src={spendingChart} alt="" height={22} />
        </div>

        <div className={styles.wrapperSimpleChart}>
          <SimpleAreaChart spendChartDataset={spendChartDataset} />
        </div>
      </div>
    </div>
  );
};

export default SpendingChart;
