export const widthStatusTitle = "130px";

export const maxWidthColumns = "250px";
export const maxWidthActions = "160px";
export const maxHeightActions = "550px";
export const maxHeightActionsMobile = "300px";

export const customTimeZoneSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    borderColor: state.isFocused || state.isSelected ? "#21a038" : "#AAAAAA",
    boxShadow: state.isFocused && "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    minWidth: "300px",
    maxWidth: "300px",
    fontSize: "16px",
    height: "34px",
    padding: "0 8px",
    paddingTop: "0px",
    color: "rgb(70, 70, 70)",

    "&:hover": {
      borderColor: "#21a038",
    },
    "@media (max-width: 500px)": {
      width: "100%",
      maxWidth: "none",
      minWidth: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "rgb(70, 70, 70)",

    // paddingTop: "2px",
  }),

  placeholder: (provided) => ({
    ...provided,
    lineHeight: 1,
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    marginTop: "4px",
    zIndex: 100000,
    fontSize: "10px !important",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#21a038" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "#21a038",
      color: "white",
    },
    padding: "10px",
    cursor: "pointer",
    fontSize: "14px ",
  }),
};
