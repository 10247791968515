import React, { useCallback, useEffect, useState } from "react";
import styles from "../../pages/cards/card-detailes/styles.module.scss";
import CardItem from "../../components/CardItem/CardItem";
import PlusIcon from "../../icons/PlusIcon";
import AccountIcon from "../../icons/AccountIcon";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import { Button } from "../../components/Button/Button";
import PauseIcon from "../../icons/PauseIcon";
import BlockedIcon from "../../icons/BlockedIcon";
import StartIcon from "../../icons/StartIcon";
import {
  changeCard,
  changeCardStatus,
  currentCardCredentials,
} from "../../api/cardsApi";
import { ModalsInteractive } from "../Modals/TeamManagementModals/ModalsInteractive";
import IconEdit from "../../icons/IconEdit";
import { displayLimits, NOTIFICATION_INFO } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import { Popover, TextInput } from "@mantine/core";
import { getCardStatus } from "../../utils/getCardStatus";
import { showToast } from "../Notification/ActionNotification";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useClientInvalidateData } from "../../hooks/useClientInvalidateData";
import SetLimitsCard from "../Modals/SetLimitsCard/SetLimitsCard";
import ReloadIcon from "../../icons/ReloadIcon";
import useGetUserRole from "../../hooks/useGetUserRole";
import clsx from "clsx";

const CardDeatailsHeader = ({
  cardDataInfo,
  isCardDataLoading,
  isCardDataFetching,
}) => {
  const [activeModal, setActiveModal] = useState(null);
  const [isLimitsOpen, setIsLimitsOpen] = useState(false);
  const [accessUsersNames, setAccessUsersNames] = useState("");
  const { handleInvalidateData } = useClientInvalidateData({
    queryKey: "cardData",
  });

  const navigate = useNavigate();

  const cardData = cardDataInfo?.data;
  const cardId = cardData?.cardId;

  const { data: cardCredentials, isLoading: isCardCredLoading } = useQuery({
    queryKey: ["cardCredentialsInfo", cardId],
    queryFn: () => currentCardCredentials(cardId),
    enabled: !!cardId,
    cacheTime: 2 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
  });
  const finallyCardCredentials = cardCredentials?.data;

  const [threeDSStatus, setThreeDSStatus] = useState(
    cardData?.enablePushNotification
  );
  const [cardStatus, setCardStatus] = useState(cardData?.statusCode);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [cardName, setCardName] = useState("");
  const { userRole } = useGetUserRole();
  useEffect(() => {
    if (cardData?.account?.users && cardData?.account?.users.length > 0) {
      setAccessUsersNames(cardData?.account?.users[0].name);
    }
    if (cardData?.name) {
      setCardName(cardData?.name);
    }
    if (cardData?.statusCode) {
      setCardStatus(cardData?.statusCode);
    }
    if (cardData?.enablePushNotification) {
      setThreeDSStatus(cardData?.enablePushNotification);
    }
  }, [
    cardData?.account?.users,
    cardData?.name,
    cardData?.statusCode,
    cardData?.enablePushNotification,
  ]);
  const handleOpenSetLimitModal = useCallback(() => setIsLimitsOpen(true), []);

  if (isCardDataLoading || isCardCredLoading)
    return <Skeleton isSpinner className={styles.skeletonHeaderCardDetails} />;

  const handleChange = (event) => {
    const { value } = event.target;
    setAccessUsersNames(value);
  };

  const handleChangeCardName = async () => {
    try {
      const payload = {
        name: cardName,
      };

      await changeCard(cardData.cardId, payload);
      showToast("success", NOTIFICATION_INFO.CHANGED_CARD_NAME);
      handleInvalidateData();
      setPopoverOpen(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleToggleCardStatus = async () => {
    const newStatus = cardStatus === "03" ? "00" : "03";

    setCardStatus(newStatus);

    try {
      const payload = {
        status: newStatus,
      };
      await changeCardStatus(cardId, payload);
      handleInvalidateData();

      showToast("success", NOTIFICATION_INFO.CHANGE_CARD_STATUS);
    } catch (error) {
      showToast("error", NOTIFICATION_INFO.CHANGE_CARD_STATUS_ERROR);
      console.error("Failed to update card status:", error);

      setCardStatus(cardStatus);
      alert("Failed to update card status. Try again later");
    }
  };

  const handleCloseCard = async () => {
    try {
      const payload = {
        status: "21",
      };
      await changeCardStatus(cardId, payload);
      showToast("cardBlocked", NOTIFICATION_INFO.CARD_CLOSED);
      handleInvalidateData();
      setActiveModal(null);
    } catch (error) {
      console.error("Failed to update card status:", error);

      setCardStatus(cardStatus);
      alert("Failed to close card. Try again later");
    }
  };

  const handleToggle3DS = async () => {
    try {
      setThreeDSStatus((prevStatus) => {
        const newStatus = !prevStatus;

        const payload = {
          enablePushNotification: newStatus,
        };

        changeCard(cardId, payload)
          .then(() => {
            showToast("success", NOTIFICATION_INFO.CHANGED_THREE_DS_STATUS);
            handleInvalidateData();
          })
          .catch((error) => {
            console.error(error);
            setThreeDSStatus(prevStatus);
          });

        return newStatus;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const blockChangeLimitsUnactiveCard = () =>
    showToast("error", NOTIFICATION_INFO.CHANGE_CARD_LIMITS_ERROR);

  const handleAссountFunding = () => {
    if (userRole === "Member") {
      showToast("error", "You have no authority to do this action.");
      return;
    }
    return navigate(`${PAGE_ROUTES.ACCOUNTS}?${cardData?.account.iban}`);
  };

  const editPopup = (
    <Popover
      opened={isPopoverOpen}
      onClose={() => {
        setPopoverOpen(false);
        setCardName(cardData?.name);
      }}
      position="bottom-start"
      withArrow
    >
      <Popover.Target>
        <div
          onClick={() => setPopoverOpen(true)}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <IconEdit />
        </div>
      </Popover.Target>

      <Popover.Dropdown className={styles.popOver}>
        <TextInput
          value={cardName}
          onChange={(e) => setCardName(e.target.value)}
          placeholder="Enter a new card name"
          required
          maxLength={30}
          rules={{
            required: "This field is required",
            maxLength: {
              value: 30,
              message: "Card name must be at most 30 characters",
            },
          }}
          mb="sm"
        />
        <Button className={styles.saveButton} onClick={handleChangeCardName}>
          Save
        </Button>
      </Popover.Dropdown>
    </Popover>
  );

  const limitsMap = cardData.limits.reduce((acc, limit) => {
    acc[limit.name] = limit;
    return acc;
  }, {});

  const { statusCard, colorContainer, colorText } = getCardStatus(
    cardData?.statusName
  );

  const closedCardStatus = cardStatus === "21";

  return (
    <>
      <div className={styles.cardDetailsContainer}>
        <CardItem
          cardNumber={cardData?.cardMask || "N/A"}
          cardType={cardData?.paymentSystem || "Unknown"}
          cardCredentials={finallyCardCredentials || {}}
        />
        <div className={styles.containerTopHeader}>
          <div className={styles.containerTopHeaderBoth}>
            <div className={styles.containerWithCardBalance}>
              <div className={styles.containerHeaderCard}>
                <p className="H1">{cardName}</p>
                {editPopup}
                <div className={styles.hideInMobile}>
                  <ReloadIcon
                    className={styles.reloadIcon}
                    onClick={handleInvalidateData}
                    style={{
                      animation: isCardDataFetching
                        ? "spin 1s linear infinite"
                        : "none",
                    }}
                  />
                </div>
              </div>
              <div className={styles.containerBalanceTxt}>
                <p className={clsx("H1", styles.hideInMobile)}>
                  €{cardData.balance}
                </p>
                <span className={styles.hideInMobile}>
                  <PlusIcon fill="white" onClick={handleAссountFunding} />
                </span>
              </div>
            </div>
            <div className={styles.containerHeaderBottomMenu}>
              <div className={styles.containerMenuCard}>
                <div className={styles.containerActions}>
                  <span
                    className={styles.statusCard}
                    style={{ background: colorContainer, color: colorText }}
                  >
                    {statusCard}
                  </span>

                  {cardStatus === "00" && (
                    <PauseIcon
                      className={styles.cursorPointer}
                      // onClick={handleToggleCardStatus}
                      onClick={() => {
                        if (cardStatus !== "50") {
                          handleToggleCardStatus();
                        }
                      }}
                      style={{
                        cursor: cardStatus === "50" ? "not-allowed" : "pointer",
                      }}
                    />
                  )}
                  {cardStatus === "03" && (
                    <StartIcon
                      style={{
                        cursor: cardStatus === "50" ? "not-allowed" : "pointer",
                      }}
                      className={styles.cursorPointer}
                      onClick={() => {
                        if (cardStatus !== "50") {
                          handleToggleCardStatus();
                        }
                      }}
                    />
                  )}
                  {!closedCardStatus && (
                    <BlockedIcon
                      style={{
                        cursor: cardStatus === "50" ? "not-allowed" : "pointer",
                      }}
                      className={styles.cursorPointer}
                      onClick={() => {
                        if (cardStatus !== "50") {
                          setActiveModal("blockCard");
                        }
                      }}
                    />
                  )}
                </div>

                <span className={styles.cardId}>
                  {cardData?.cardId
                    ? `#${cardData.cardId.slice(-6)}`
                    : "No card info"}
                </span>
              </div>
              <div className={styles.containerMobileBalance}>
                <div className={styles.mobileContainerBalance}>
                  <p className="H1">€{cardData.balance}</p>

                  <ReloadIcon
                    className={styles.reloadIconMobile}
                    onClick={handleInvalidateData}
                    style={{
                      animation: isCardDataFetching
                        ? "spin 1s linear infinite"
                        : "none",
                    }}
                  />
                </div>

                <span>
                  <PlusIcon fill="white" onClick={handleAссountFunding} />
                </span>
              </div>
              <div className={styles.containerAccountInfo}>
                <div className={styles.containerIbanTitle}>
                  <div>
                    <span className={styles.accountTitile}>Account</span>
                  </div>
                  <p className={styles.cardIban}>
                    "{cardData.account.name}" {cardData.account.iban}
                  </p>
                </div>
                <span className={styles.containerAccountIcon}>
                  <AccountIcon
                    onClick={() =>
                      navigate(
                        `${PAGE_ROUTES.PAYMENTS}?iban=${cardData.account.iban}`
                      )
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className={styles.containerAdditionalInfoCard}>
            <div className={styles.gaps}>
              <p className={clsx("SH", styles.titleBoldMobile)}>
                Billing adress
              </p>
              <span className={styles.adress}>
                {cardData.billingAddress?.street ||
                cardData.billingAddress?.postalCode ||
                cardData.billingAddress?.city ||
                cardData.billingAddress?.country ? (
                  <>
                    {cardData.billingAddress.country || ""}{" "}
                    {cardData.billingAddress.city || ""}{" "}
                    {cardData.billingAddress.street || ""}{" "}
                    {cardData.billingAddress.postalCode || ""}{" "}
                  </>
                ) : (
                  <span>No address available</span>
                )}
              </span>
            </div>
            <div className={styles.gaps}>
              <p className={clsx("SH", styles.titleBoldMobile)}>3DS Status</p>
              <div>
                <span>{cardData.enablePushNotification ? "On" : "Off"} </span>
                <Switch checked={threeDSStatus} onChange={handleToggle3DS} />
              </div>
            </div>{" "}
            <div className={styles.containerAddUser}>
              <div className={styles.containerHeaderAddUser}>
                <p className="SH">Who has access to card’s IBAN</p>
              </div>
              <div style={{ width: "100%" }}>
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                  }}
                >
                  <InputLabel
                    id="network-select-label"
                    shrink
                    sx={{
                      backgroundColor: "white",
                      padding: "0px",
                    }}
                  >
                    Users
                  </InputLabel>
                  <Select
                    labelId="network-select-label"
                    id="network-select"
                    value={
                      cardData.account?.users &&
                      cardData.account.users.length > 0
                        ? accessUsersNames
                        : "no_accounts"
                    }
                    label="Select the account"
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected === "no_accounts" ? "No accounts" : selected
                    }
                  >
                    {cardData.account?.users &&
                    cardData.account.users.length > 0 ? (
                      cardData.account.users.map((user) => (
                        <MenuItem key={user.id} value={user.name}>
                          <ListItemText
                            primary={`${user.name} ${user.email}`}
                            style={{ fontSize: "12px" }}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled value="no_accounts">
                        <ListItemText
                          primary="No accounts"
                          style={{ fontSize: "12px" }}
                        />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>{" "}
            <div className={styles.gaps}>
              <p className={clsx("SH", styles.titleBoldMobile)}>
                Creation Date
              </p>
              <DateFormatter
                date={cardData.creationDate}
                withTime={true}
                className={styles.timeCreated}
              />
            </div>{" "}
            <div className={styles.gaps}>
              <div className={styles.containerLimitsTitle}>
                <p className={clsx("SH", styles.titleBoldMobile)}>Limits</p>

                <IconEdit
                  onClick={() =>
                    cardStatus === "03" || cardStatus === "50"
                      ? blockChangeLimitsUnactiveCard()
                      : handleOpenSetLimitModal()
                  }
                />
              </div>

              <div className={styles.wrapperSpendsPeriod}>
                {displayLimits.map(({ label, name }) => {
                  const limit = limitsMap[name];
                  return (
                    <div key={name} className={styles.containerSpend}>
                      <span style={{ color: "#aaaaaa", fontSize: "12px" }}>
                        {label}
                      </span>
                      <span>€{limit ? limit.value : "N/A"}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalsInteractive
        isCommonTxt={true}
        modalData={cardData}
        activeModal={activeModal}
        onCancel={() => setActiveModal(null)}
        onSubmit={
          // activeModal === "setLimits" ? onSubmitCardLimits : handleCloseCard
          handleCloseCard
        }
        idForm="modal-form"
      />
      {isLimitsOpen && (
        <SetLimitsCard
          cardId={cardId}
          setActiveModal={setIsLimitsOpen}
          data={cardData}
          keyToInvalidate="cardDataInformation"
        />
      )}
    </>
  );
};

export default React.memo(CardDeatailsHeader);
