import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import styles from "./styles.module.scss";
import clsx from "clsx";
import ContainerNews from "./container/ContainerNews";
import NewsIcon from "../../icons/NewsIcon";

const NewsContainer = ({ data, isFetching, isLoading, width }) => {
  return (
    <>
      <div className={styles.containerRateAndNewsSmall}>
        <div className={styles.containerNews}>
          <div className={styles.containerTitle}>
            <div className={styles.containerTitleText}>
              <p className="H3">LATEST NEWS</p>
              <NewsIcon fill="#222222" height={22} />
            </div>
            <Link
              className={clsx("buttons", styles.linkTitle)}
              to={PAGE_ROUTES.NEWS}
            >
              All news
            </Link>
          </div>
          <ContainerNews />
        </div>
      </div>
    </>
  );
};

export default NewsContainer;
