import { format } from "date-fns";
import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getWidgetsValues = async () => {
  const result = await axiosAuth.get(API_PATHS.DASHBOARD_WIDGETS_VALUES);

  return result.data;
};

//WITH QUERY TANSTACK
// export const getWidgetValues = () =>
// axiosAuth.get(API_PATHS.DASHBOARD_WIDGETS_VALUES);

export const getWidgetValues = async ({ from, to }) => {
  const res = await axiosAuth.get(API_PATHS.DASHBOARD_WIDGETS_VALUES, {
    params: {
      dateFrom: format(from, "yyyy-MM-dd"),
      dateTo: format(to, "yyyy-MM-dd"),
    },
  });
  return res;
};
