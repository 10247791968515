import { Link, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "./MainLayout.module.scss";
import FooterMainLayout from "../../components/footer/FooterMainLayout/FooterMainLayout";
import { Notifications } from "../Notifications/Notifications";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import useLogoutUser from "../../hooks/useLogoutUser";
import Modal from "../Modals/Modal";
import ReturnTo from "../../components/ReturnTo/ReturnTo";
import CodeInputField from "../CodeInputField/CodeInputField";
import styleModalPin from "../../modules/Modals/ChangePinModal/styles.module.scss";
import { PAGE_ROUTES } from "../../pages/constants";
import { refreshTokenByPinCode } from "../../api/pinCode";
import { NOTIFICATION_INFO } from "../../constants/constants";
import { showToast } from "../Notification/ActionNotification";
import ArrowLeft from "../../icons/ArrowLeft";

const MainLayout = () => {
  const { token, setToken } = useContext(AuthContext);

  const { isShowConfirmPinModal, setIsShowConfirmPinModal, logout } =
    useLogoutUser({
      token,
      setToken,
    });

  const handleSubmitPinCodeForm = async (data) => {
    try {
      const formattedData = { pin: data.pin.join("") };
      const res = await refreshTokenByPinCode(formattedData);

      if (res.status === 200) {
        console.log("INVOLVED");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);

        setIsShowConfirmPinModal(false);
        showToast("success", NOTIFICATION_INFO.LOGIN);
      }
    } catch (error) {
      console.error("error:", error);
      const err = error?.response?.data?.data?.message;
      showToast("error", err || NOTIFICATION_INFO.PIN_CHANGED_ERROR);
    }
  };

  return (
    <div className={styles.mainLayout}>
      <div className={styles.wrapperMainLayout}>
        <Sidebar />

        <div className={styles.childrenLayout}>
          <MobileHeader />
          <div className={styles.containerLayout}>
            <Outlet />
            <Notifications />
            <FooterMainLayout />
          </div>
        </div>
      </div>

      {isShowConfirmPinModal && (
        <Modal withCloseIcon={false} withBlure={true}>
          <div className={styleModalPin.containerContentPinPage}>
            <h1 className={styleModalPin.h1PinCode}> Enter your PIN code</h1>
            <p className={styleModalPin.paragrapghPinCodePage}>
              {" "}
              You've been inactive for a long time, enter the PIN code to log
              in.
            </p>

            <CodeInputField
              handleSubmitCodeForm={handleSubmitPinCodeForm}
              withConfirmBtn={false}
              autoSubmitOnComplete={true}
            />

            <p className={styleModalPin.bottomAdditionalText}>
              Forgot your PIN code?
            </p>
            <Link
              to={PAGE_ROUTES.LOGIN}
              onClick={logout}
              className={styleModalPin.linkGoLogin}
            >
              <ArrowLeft style={{ height: "15px" }} /> Please log in again
            </Link>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MainLayout;
