import React, { useState, useRef, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import {
  subDays,
  startOfMonth,
  endOfMonth,
  format,
  isSameDay,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  subMonths,
} from "date-fns";
import "react-day-picker/dist/style.css";
import calendar from "../../images/Calendar.svg";
import { showToast } from "../../modules/Notification/ActionNotification";
import styles from "./styles.module.scss";

const PRESETS = [
  {
    label: "Today",
    range: [startOfToday(), endOfToday()],
  },
  {
    label: "Yesterday",
    range: [startOfYesterday(), endOfYesterday()],
  },
  {
    label: "Last 7 days",
    range: [subDays(endOfToday(), 6), endOfToday()],
  },
  {
    label: "Last 30 days",
    range: [subDays(endOfToday(), 29), endOfToday()],
  },
  {
    label: "This month",
    range: [startOfMonth(new Date()), endOfToday()],
  },
  {
    label: "Last month",
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
  },
];

export default function DayPickerRangeSelect({ value, onAccept }) {
  const [showPicker, setShowPicker] = useState(false);
  const [displayedMonth, setDisplayedMonth] = useState(
    value?.[0] || new Date()
  );

  const pickerRef = useRef(null);

  const [range, setRange] = useState({
    from: value?.[0] || new Date(),
    to: value?.[1] || new Date(),
  });

  const isSameRange = (aFrom, aTo, bFrom, bTo) => {
    return isSameDay(aFrom, bFrom) && isSameDay(aTo, bTo);
  };

  useEffect(() => {
    if (Array.isArray(value)) {
      setRange({ from: value[0], to: value[1] });
      setDisplayedMonth(value[0]);
    }
  }, [value]);

  useEffect(() => {
    function handleClickOutside(e) {
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        setShowPicker(false);
      }
    }
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showPicker]);

  const handleSelect = (updRange) => {
    if (updRange?.from && updRange?.to) {
      setRange(updRange);
    }
  };

  const handlePreset = (from, to) => {
    setRange({ from, to });
    setDisplayedMonth(from);
  };

  let displayLabel = "";
  const matchedPreset = PRESETS.find(({ range: [pf, pt] }) =>
    isSameRange(range.from, range.to, pf, pt)
  );

  if (matchedPreset) {
    displayLabel = matchedPreset.label;
  } else {
    const fromText = range.from
      ? format(range.from, "dd.MM.yyyy")
      : "__.__.____";
    const toText = range.to ? format(range.to, "dd.MM.yyyy") : "__.__.____";
    displayLabel = `${fromText} - ${toText}`;
  }

  const handleAccept = () => {
    if (!range.from || !range.to) {
      showToast("error", "You need to select the date");
      return;
    }
    onAccept?.(range.from, range.to);
    setShowPicker(false);
  };

  console.log("displayedMonth", displayedMonth);
  return (
    <div
      className={styles.containerDataRangePicker}
      style={{ position: "relative", display: "inline-block" }}
      ref={pickerRef}
    >
      <button
        type="button"
        onClick={() => setShowPicker((prev) => !prev)}
        className={styles.buttonOpenRangePicker}
        style={{ border: showPicker ? "1px solid #21a038" : "" }}
      >
        {displayLabel}
        <img src={calendar} alt="" height={22} />
      </button>

      {showPicker && (
        <div className={styles.wrapperRangePicker}>
          <div className={styles.containerRangePicker}>
            <div className={styles.presetsButtons}>
              {PRESETS.map(({ label, range: [pf, pt] }) => {
                const isActive = isSameRange(range.from, range.to, pf, pt);
                return (
                  <button
                    key={label}
                    type="button"
                    onClick={() => handlePreset(pf, pt)}
                    className={styles.presetButton}
                    style={{
                      outline: isActive
                        ? "2px solid #21a038"
                        : "1px solid #21a038",
                    }}
                  >
                    {label}
                  </button>
                );
              })}
            </div>
          </div>

          <div className={styles.containerDayPicker}>
            <DayPicker
              mode="range"
              selected={range}
              onSelect={handleSelect}
              month={displayedMonth}
              onMonthChange={setDisplayedMonth}
              style={{ fontSize: "14px" }}
            />
            <button className={styles.buttonAcceptRange} onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
