import CloseIcon from "../../icons/CloseIcon";
import styles from "./styles.module.scss";

const Modal = ({
  children,
  withCloseIcon = false,
  closeIconColor,
  onCLose,
  withBlure = false,
}) => {
  return (
    <div
      className={styles.containerModal}
      style={{ backdropFilter: withBlure ? "blur(25px)" : "" }}
    >
      <div className={styles.modal}>
        {withCloseIcon && (
          <CloseIcon
            className={styles.closePinModalIcon}
            color={closeIconColor}
            onClick={onCLose}
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
