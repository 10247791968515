import React from "react";
import ArrowLeft from "../../icons/ArrowLeft";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const ReturnTo = ({ returnToText, routeReturn, onClick }) => {
  return (
    <p className={styles.titleBackLogin}>
      <ArrowLeft style={{ height: "15px" }} /> Return to{" "}
      <Link to={routeReturn} onClick={onClick && onClick}>
        {" "}
        {returnToText}
      </Link>
    </p>
  );
};

export default ReturnTo;
