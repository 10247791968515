import { useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import styles from "./styles.module.scss";
import AllCards from "../../modules/AllCards/AllCards";
import CardIssuance from "../../modules/CardIssuance/CardIssuance";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";

const Cards = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "All cards",
    },
    {
      title: "Cards issuance",
    },
  ];

  const componentsToRender = [<AllCards />, <CardIssuance />];
  const titlesToRender = ["Cards", "Cards"];

  return (
    <div className={styles.wrapperContainerCards}>
      <div className={styles.containerNavCards}>
        <div className={styles.wrapperContainerAllCards}>
          <h1 className="H1">{titlesToRender[activeTab]}</h1>
          <div>
            <DateFormatter
              fullLongFormat={true}
              live={true}
              className="titleDateFormatter"
            />
          </div>
        </div>
        <Pagination tabs={tabs} setActive={setActiveTab} active={activeTab} />
      </div>

      <div>{componentsToRender[activeTab]}</div>
    </div>
  );
};

export default Cards;
