import { axiosPublic } from "./axios";
import { API_PATHS } from "./constants";

export const refreshTokenByPinCode = (data) => {
  const refreshToken = localStorage.getItem("refreshToken");

  return axiosPublic.post(API_PATHS.REFRESH_TOKEN, data, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
};
