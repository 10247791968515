import ItemBlockInfo from "../../components/itemBlockInfo/ItemBlockInfo";
import { PAGE_ROUTES } from "../../pages/constants";
import {
  HIGHLIGH_POSITION_FIRST,
  HIGHLIGH_POSITION_LAST,
} from "../../constants/constants";
import styles from "./styles.module.scss";
import React from "react";
import threeCardsInARow from "../../images/ThreeCardInARow.png";
import clsx from "clsx";
import Skeleton from "../../components/Skeleton/Skeleton";
import DashBoardTable from "../Tables/DashBoard/DashBoardTable";
import NewsContainer from "../../components/news/NewsContainer";

import useIsMobile from "../../hooks/useIsMobile";
import DeclineRate from "../../components/itemBlockInfo/declineRate/DeclineRate";
import SpendingChart from "../SpendingChart/SpendingChart";
import spendPng from "../../images/Spend.svg";
import card from "../../images/Card.svg";

const DashBoardItems = ({ data, isLoading, isFetching }) => {
  const dashboardData = data?.data?.data || {};
  const isMobile = useIsMobile();

  return (
    <>
      <div className={styles.containerDashboard}>
        <div className={styles.containerTwoDashboardInfoElements}>
          <div className={styles.containerWithDashboardInfo}>
            {isMobile && (
              <ItemBlockInfo
                title="AVAILABLE"
                titleColored="BALANCE"
                additContent={
                  isLoading || isFetching ? (
                    <Skeleton className={styles.widgetSkeleton} />
                  ) : (
                    <div className="H1">
                      €
                      {dashboardData?.totalBalanceAmount?.value
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                  )
                }
                linkTitle="Details"
                href={`${PAGE_ROUTES.ACCOUNTS}`}
                highlightPosition={HIGHLIGH_POSITION_LAST}
              />
            )}
            <div className={styles.containerWithCardsInfo}>
              <ItemBlockInfo
                title="SPEND"
                icon={spendPng}
                additContent={
                  isLoading || isFetching ? (
                    <Skeleton className={styles.widgetSkeleton} />
                  ) : (
                    <div className="H1">
                      €{dashboardData.totalSpendAtPeriod?.value || 0}
                    </div>
                  )
                }
                linkTitle="Details"
                href={`${PAGE_ROUTES.PAYMENTS}?spend-for-day=true`}
                highlightPosition={HIGHLIGH_POSITION_LAST}
              />
              <DeclineRate
                data={data}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            </div>

            <div className={styles.containerWithCardsInfo}>
              <ItemBlockInfo
                title="CARDS ISSUED"
                icon={card}
                titleColored=""
                additContent={
                  isLoading || isFetching ? (
                    <Skeleton className={styles.widgetSkeletonWithCard} />
                  ) : (
                    <div className="H1">
                      <div className={styles.containerImgCard}>
                        <p>
                          {dashboardData.cardsIssuedAtPeriod?.value || 0}{" "}
                          <span className={clsx("body2", styles.cardText)}>
                            Cards
                          </span>
                        </p>
                        <img
                          src={threeCardsInARow}
                          alt=""
                          height={25}
                          width={40}
                        />
                      </div>
                    </div>
                  )
                }
                linkTitle="All cards"
                href={`${PAGE_ROUTES.CARDS}?issued-today=true`}
              />
              <ItemBlockInfo
                title="CARDS"
                titleColored="ALL ACTIVE"
                icon={card}
                additContent={
                  isLoading || isFetching ? (
                    <Skeleton className={styles.widgetSkeletonWithCard} />
                  ) : (
                    <div className="H1">
                      <div className={styles.containerImgCard}>
                        <div className={styles.conainerAllCards}>
                          {dashboardData.allValidCards?.value || 0}
                          <div className={styles.twoRowsText}>
                            <span className={clsx("body2", styles.cardText)}>
                              All active
                            </span>
                            <span className={clsx("body2", styles.cardText)}>
                              team cards
                            </span>
                          </div>
                        </div>
                        <img
                          src={threeCardsInARow}
                          alt=""
                          height={25}
                          width={40}
                        />
                      </div>
                    </div>
                  )
                }
                linkTitle="All cards"
                highlightPosition={HIGHLIGH_POSITION_FIRST}
                href={PAGE_ROUTES.CARDS}
              />
            </div>

            {/* <div className={styles.containerNewsAndDeclineRate}>
              <NewsContainer
                data={data}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </div> */}
          </div>

          <div className={styles.containerSpendingChart}>
            <SpendingChart
              spendChartDataset={dashboardData.spendChartDataset}
            />
          </div>
        </div>

        <div className={styles.containerDashboardTableAndNews}>
          <div className={styles.containerTableDashboard}>
            <DashBoardTable />
          </div>
          <div className={styles.containerNewsAndDeclineRate}>
            <NewsContainer
              data={data}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DashBoardItems);
