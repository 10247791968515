import clsx from "clsx";
import React from "react";
import { Button } from "../Button/Button";
import TelegramIcon from "../../icons/TelegramIcon";
import styles from "./styles.module.scss";

const AnyTroublesBlock = () => {
  return (
    <div className={styles.loginContainerRegistration}>
      <div className={styles.loginContainerRegistrationText}>
        <p className="semiHedline">Any troubles?</p>
        <p className={clsx("primaryText", styles.primaryText)}>
          Contact our manager{" "}
          <a
            href="https://t.me/v_astanin"
            target="_blank"
            className={styles.loginRecoverLink}
            rel="noreferrer"
          >
            @fbm_ivan
          </a>{" "}
          for help
        </p>
      </div>
      <Button className={styles.loginContainerRegistrationButton}>
        <a
          href="https://t.me/v_astanin"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact <TelegramIcon />
        </a>
      </Button>
    </div>
  );
};

export default AnyTroublesBlock;
