import React from "react";
import Modal from "../Modal";
import CodeInputField from "../../CodeInputField/CodeInputField";
import styles from "./styles.module.scss";
import { changeUserData } from "../../../api/userApi";
import { NOTIFICATION_INFO } from "../../../constants/constants";
import { showToast } from "../../Notification/ActionNotification";

const ChangePinModal = ({ onCLose }) => {
  const handleSubmitPinCodeForm = async (data) => {
    try {
      const formattedData = { pin: data.pin.join("") };
      const res = await changeUserData(formattedData);

      if (res.status === 200) {
        console.log("res from change", res);
        showToast("success", NOTIFICATION_INFO.PIN_SUCCESSEFULLY_CHANGED);
        onCLose();
      }
    } catch (error) {
      console.error("error:", error);
      showToast("error", NOTIFICATION_INFO.PIN_CHANGED_ERROR);
    }
  };

  return (
    <Modal withCloseIcon="true" closeIconStyles="#232323" onCLose={onCLose}>
      <div className={styles.containerContentPinPage}>
        <h1 className={styles.h1PinCode}> Create a new PIN code</h1>
        <p className={styles.paragrapghPinCodePage}>
          {" "}
          The PIN code is needed for quick login. Think of and memorize it.
        </p>

        <CodeInputField handleSubmitCodeForm={handleSubmitPinCodeForm} />

        <p className={styles.bottomAdditionalText}>
          You can change your PIN code at any time.
        </p>
      </div>
    </Modal>
  );
};

export default ChangePinModal;
