import React, { useContext } from "react";
import styles from "./styles.module.scss";
import CodeInputField from "../../modules/CodeInputField/CodeInputField";
import { PAGE_ROUTES } from "../constants";
import ReturnTo from "../../components/ReturnTo/ReturnTo";
import AnyTroublesBlock from "../../components/AnyTroublesBlock/AnyTroublesBlock";
import { changeUserData } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../constants/constants";
import useLogoutUser from "../../hooks/useLogoutUser";
import { AuthContext } from "../../context/AuthContext";

const CreatePin = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const { logout } = useLogoutUser({ setToken });

  const handleSubmitPinCodeForm = async (data) => {
    try {
      const formattedData = { pin: data.pin.join("") };
      const res = await changeUserData(formattedData);

      if (res.status === 200) {
        console.log(res);
        navigate(PAGE_ROUTES.DASHBOARD);
        showToast("login", NOTIFICATION_INFO.LOGIN);
      }
    } catch (error) {
      console.error("error:", error);
      showToast("error", NOTIFICATION_INFO.LOGIN);
    }
  };

  return (
    <div className={styles.wrapperPage}>
      <div className={styles.containerContentPinPage}>
        <h1 className={styles.h1PinCode}> Create a PIN code</h1>
        <p className={styles.paragrapghPinCodePage}>
          {" "}
          The PIN code is needed for quick login. Think of and memorize it.
        </p>

        <CodeInputField handleSubmitCodeForm={handleSubmitPinCodeForm} />

        <ReturnTo
          returnToText="login page"
          routeReturn={PAGE_ROUTES.LOGIN}
          onClick={logout}
        />

        <AnyTroublesBlock />
      </div>
    </div>
  );
};

export default CreatePin;
