import { useForm } from "react-hook-form";
import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../constants";
import { useEffect, useState } from "react";
import { changeUserData, getUserData } from "../../../api/userApi";
import styles from "./styles.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";

const ProfileChange = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [nickName, setNickName] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData();
        setProfileData(result.data);

        reset({
          name: result.data.name || "No nickname info",
          nickName: result.data.telegram || "",
        });
        setNickName(result.data.telegram);
      } catch (error) {
        console.error("Error in receiving profile data.", error);
      }
    };

    fetchData();
  }, [reset]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const me = await getUserData();
      if (me && me.data.id) {
        const updatedData = {
          name: data.name,
          telegram: data.nickName,
        };

        await changeUserData(updatedData);
        queryClient.invalidateQueries(["userProfileSettings"]);
        showToast("changePassword", NOTIFICATION_INFO.PROFILE_DATA_CHANGED);

        setIsLoading(false);
        navigate(PAGE_ROUTES.PROFILE);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      showToast("error", error.response.data?.data?.message || "Server error");
      setIsLoading(false);
    }
  };

  const handleBackToProfile = () => {
    return navigate(PAGE_ROUTES.PROFILE);
  };

  const handleNickNameChange = (event) => {
    const value = event.target.value;

    if (value.startsWith("@")) {
      setNickName(value);
    } else {
      setNickName("@" + value.slice(1));
    }
  };

  return (
    <div className={styles.profileChangePas}>
      <h1>Change profile's info</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.profileChangeForm}
      >
        <div className={styles.profileChangeContainer}>
          <div className={styles.profileChangeInputs}>
            <p className="semiHedline">Name</p>
            <div className={styles.containerInput}>
              <input
                maxLength={30}
                {...register("name", {
                  required: "Name is required",

                  minLength: {
                    value: 6,
                    message:
                      "The Name field must contain at least 6 characters",
                  },
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
          </div>
          <div className={styles.profileChangeInputs}>
            <p className="semiHedline">Telegram</p>
            <div className={styles.containerInput}>
              <input
                maxLength={30}
                {...register("nickName", {
                  required: "Telegram is required",
                  minLength: {
                    value: 6,
                    message:
                      "The Nickname field must contain at least 6 characters",
                  },
                })}
                value={nickName}
                onChange={handleNickNameChange}
              />
              {errors.nickName && <p>{errors.nickName.message}</p>}
            </div>
          </div>
          <div className={styles.changeProfileButtons}>
            <Button type="submit" isLoading={isLoading}>
              Change
            </Button>
            <Button
              className={styles.cancelBtn}
              type="button"
              onClick={handleBackToProfile}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileChange;
