import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../pages/constants";
import { showToast } from "../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../constants/constants";
import { useQueryClient } from "@tanstack/react-query";
import eventEmitter from "../utils/emitter/EventEmitter";

const useLogoutUser = ({ setToken, token }) => {
  const [isShowConfirmPinModal, setIsShowConfirmPinModal] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    setToken("");
    queryClient.clear();
    navigate(PAGE_ROUTES.LOGIN);
    showToast("blocked", NOTIFICATION_INFO.LOGOUT); // временно изменено
    setIsShowConfirmPinModal(false);
    // showToast("blocked", NOTIFICATION_INFO.ACCOUNT_BLOCKED_INFORM);
  }, []);

  // const banUser = useCallback(() => {
  //   localStorage.removeItem("token");
  //   setToken("");
  //   queryClient.clear();
  //   navigate(PAGE_ROUTES.LOGIN);
  //   showToast("success", NOTIFICATION_INFO.LOGOUT);
  // }, []);

  const handleShowConfirmPinModal = useCallback(() => {
    setIsShowConfirmPinModal(true);
  }, []);

  useEffect(() => {
    eventEmitter.on("showPinModal", handleShowConfirmPinModal);
    eventEmitter.on("uncorrectPin_logoutUser", logout);

    return () => {
      eventEmitter.off("showPinModal", handleShowConfirmPinModal);
      eventEmitter.off("uncorrectPin_logoutUser", logout);

      // eventEmitter.off("ban", banUser);
    };
  }, [handleShowConfirmPinModal, logout]);

  return { isShowConfirmPinModal, logout, setIsShowConfirmPinModal };
};

export default useLogoutUser;
