import { differenceInDays, format } from "date-fns";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import useIsMobile from "../../hooks/useIsMobile";

const SimpleAreaChart = ({ spendChartDataset }) => {
  const transformSpendChartData = (data) => {
    return data?.map((item) => ({
      name: item.measurement,
      value: item.value,
      rawTime: new Date(item.timestamp),
    }));
  };

  const chartData = transformSpendChartData(spendChartDataset);
  const isMobile = useIsMobile();

  if (!chartData?.length) return null;

  const startDate = chartData[0]?.rawTime;
  const type = chartData?.[0]?.name;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const date = payload[0].payload.rawTime;
      const formatter = new Intl.DateTimeFormat("en-GB", {
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "short",
      });

      return (
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "8px 12px",
            borderRadius: "8px",
            border: "1px solid #bebebe",
          }}
        >
          <div
            style={{
              color: "#000",
              fontSize: "13px",
              fontWeight: 500,
              marginBottom: 4,
            }}
          >
            {formatter.format(date)}
          </div>
          <div style={{ color: "#21a038", fontSize: "13px", fontWeight: 600 }}>
            €{Number(payload[0].value).toFixed(2)}
          </div>
        </div>
      );
    }

    return null;
  };
  const formatCurrency = (value) => {
    if (value === 0) return "€0";

    if (value < 1000) {
      return `€${Math.round(value)}`;
    }

    if (value < 1_000_000) {
      const abbreviated = value / 1000;
      if (abbreviated < 10) {
        return `€${abbreviated.toFixed(1).replace(/\.0$/, "")}k`; // €1.2k
      }
      return `€${Math.round(abbreviated)}k`; // €12k – €999k
    }

    const millions = value / 1_000_000;
    if (millions < 10) {
      return `€${millions.toFixed(1).replace(/\.0$/, "")}m`; // €1.2m
    }

    return `€${Math.round(millions)}m`; // €10m – €999m
  };

  const calculateXAxisStep = () => {
    if (type === "1d") {
      const start = chartData[0]?.rawTime;
      const end = chartData[chartData.length - 1]?.rawTime;
      if (!start || !end) return 1;

      const totalDays = differenceInDays(end, start);

      if (totalDays <= 30) return 2; // каждые 2 дня
      if (totalDays <= 70) return 10; // каждые 10 дней
      return 15; // каждые 15 дней
    }

    return 1;
  };

  const xAxisStep = calculateXAxisStep();

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart
        data={chartData}
        margin={{ top: 10, right: 30, left: -25, bottom: 0 }}
      >
        <CartesianGrid stroke="#dcdada6a" strokeDasharray="2 2" />
        <XAxis
          dataKey="rawTime"
          stroke="#bebebe"
          fontSize={12}
          interval={0}
          tickFormatter={(value) => {
            const date = new Date(value);

            if (type === "1h") {
              const hours = date.getHours();
              return hours % (isMobile ? 4 : 2) === 0
                ? format(date, "HH:mm")
                : "";
            }

            if (type === "1d" && startDate) {
              const diff = differenceInDays(date, startDate);

              if (diff % xAxisStep === 0) {
                return `${diff + 1}`;
              }
              return "";
            }

            return "";
          }}
        />
        <YAxis
          stroke="#bebebe"
          tickFormatter={formatCurrency}
          width={65}
          fontSize={12}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#21a038"
          fill="url(#colorValue)"
          strokeWidth={2}
        />

        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#21a038" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#21a038" stopOpacity={0} />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SimpleAreaChart;
