import { useForm } from "react-hook-form";
import { Button } from "../../../components/Button/Button";
import { AuthContext } from "../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import {
  resendNew2faCode,
  twoFaCheckCode,
} from "../../../api/twoFactorAuthentication";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../constants";
import styles from "./TwoFaPage.module.scss";
import {
  NOTIFICATION_INFO,
  SECONDS_TO_TESEND_2FA_CODE,
} from "../../../constants/constants";
import TelegramIcon from "../../../icons/TelegramIcon";
import ArrowLeft from "../../../icons/ArrowLeft";
import clsx from "clsx";
import { showToast } from "../../../modules/Notification/ActionNotification";
import ReturnTo from "../../../components/ReturnTo/ReturnTo";
import AnyTroublesBlock from "../../../components/AnyTroublesBlock/AnyTroublesBlock";

const TwoFaPage = () => {
  const { email, setToken, userData, userDataIsLoading, refetchUserData } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeTimeout, setIsCodeTimeout] = useState(false);
  const [timeLeft, setTimeLeft] = useState(SECONDS_TO_TESEND_2FA_CODE);

  const [pendingNavigation, setPendingNavigation] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const defaultValue = {
    twoFaCode: "",
  };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({ defaultValue });

  const { twoFaCode } = watch();

  useEffect(() => {
    if (errors && errors.serverError) {
      clearErrors("serverError");
    }
  }, [twoFaCode, errors]);

  useEffect(() => {
    if (!location.state?.fromLogin) {
      navigate(PAGE_ROUTES.LOGIN, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          setIsCodeTimeout(true);
          return;
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, navigate]);

  const handle2FaVerification = async (data) => {
    setIsLoading(true);

    try {
      const body = {
        email: email,
        code: data.twoFaCode,
      };

      const response = await twoFaCheckCode(body);
      if (response && response.data.token) {
        setIsLoading(false);

        setToken(response.data.token);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        setPendingNavigation(true);
      } else {
        console.error("Server error");
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        setError("serverError", {
          message:
            error.response?.data?.message ||
            error.response?.data?.data?.message ||
            "Unexpected error, try login later",
        });
      } else {
        setError("serverError", { message: "Server Error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setTimeLeft(SECONDS_TO_TESEND_2FA_CODE);
      setIsCodeTimeout(false);

      await resendNew2faCode({ email });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError("serverError", {
          message:
            error.response.data.message || "Unexpected error, try login later",
        });
      } else {
        setError("serverError", { message: "Server Error" });
      }
    }
  };

  useEffect(() => {
    if (pendingNavigation && !userDataIsLoading && userData) {
      const { pinRequired } = userData;
      if (pinRequired) {
        navigate(PAGE_ROUTES.CREATE_PIN);
      } else {
        navigate(PAGE_ROUTES.DASHBOARD);
        showToast("login", NOTIFICATION_INFO.LOGIN);
      }
      setPendingNavigation(false);
    }
  }, [pendingNavigation, userData, userDataIsLoading]);

  return (
    <div className={styles.twoFaPage}>
      <div className={styles.twoFaContainer}>
        <div className={styles.twoFaTitle}>
          <h1>Two-factor authentication</h1>
          <p className="primaryText">
            To complete your account login, please enter the two-factor
            authentication code sent to your email.
          </p>
        </div>

        <form
          onSubmit={handleSubmit(handle2FaVerification)}
          className={styles.twoFaForm}
        >
          <div className={styles.twoFaFormContainer}>
            <div className={styles.twoFaFormInput}>
              <label htmlFor="code" className="primaryText">
                Two Factor Authentication
              </label>
              <input
                id="code"
                type="text"
                placeholder="Enter the 2FA code sent to your email"
                {...register("twoFaCode", {
                  required: "2fa code is required",
                })}
              />
              {errors.serverError && (
                <span className={styles.twoFaFormError}>
                  {errors.serverError.message}
                </span>
              )}
            </div>

            <div className={styles.twoFaButtons}>
              {isCodeTimeout ? (
                <Button onClick={handleResendCode}>Resend code</Button>
              ) : (
                <div className={styles.twaPageTimer}>{timeLeft} sec</div>
              )}
            </div>
          </div>
          {errors.email && <p>{errors.twoFaCode.message}</p>}

          <Button isLoading={isLoading} type="submit">
            Login
          </Button>
        </form>

        <ReturnTo
          returnToText="previous page"
          routeReturn={PAGE_ROUTES.LOGIN}
        />
        <AnyTroublesBlock />
      </div>
    </div>
  );
};

export default TwoFaPage;
