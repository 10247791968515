import clsx from "clsx";
import TeamManagementTable from "../../modules/Tables/TeamManagementTable";
import styles from "./styles.module.scss";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";

const TeamManagement = () => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapperHeaderTM}>
        <p className={clsx("H1", styles.titleTable)}>Team management</p>
        <div>
          <DateFormatter
            fullLongFormat={true}
            live={true}
            className="titleDateFormatter"
          />
        </div>
      </div>
      <div className={styles.containerTable}>
        <TeamManagementTable />
      </div>
    </div>
  );
};

export default TeamManagement;
