import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { PIN_LENGTH } from "../../constants/constants";
import styles from "./styles.module.scss";
import { Button } from "../../components/Button/Button";

const CodeInputField = ({
  handleSubmitCodeForm,
  withConfirmBtn = true,
  autoSubmitOnComplete = false,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      pin: Array(PIN_LENGTH).fill(""),
    },
  });

  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (/^\d$/.test(value)) {
      clearErrors(`pin.${index}`);
      setValue(`pin.${index}`, value);

      if (index < PIN_LENGTH - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      //submit if we have 4 pin values
      if (autoSubmitOnComplete) {
        const pinValues = getValues("pin").join("");
        if (pinValues.length === PIN_LENGTH) {
          handleSubmit((data) => handleSubmitCodeForm(data))();
        }
      }
    } else {
      setValue(`pin.${index}`, "");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => handleSubmitCodeForm(data))}>
      <div className={styles.containerContentFields}>
        <div className={styles.containerPinFields}>
          {Array.from({ length: PIN_LENGTH }).map((_, index) => {
            const inputRegister = register(`pin.${index}`, {
              required: "This fields are required",
              pattern: {
                value: /^[0-9]$/,
                message: "Accepted only the numbers: 0 to 9",
              },
            });
            return (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                maxLength="1"
                className={styles.fieldContainer}
                style={{
                  border: errors.pin?.[index]
                    ? "1px solid #ff4d4d"
                    : "1px solid transparent",
                }}
                {...inputRegister}
                ref={(e) => {
                  inputRegister.ref(e);
                  inputRefs.current[index] = e;
                }}
                onChange={(e) => handleInputChange(e, index, inputRegister)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            );
          })}
        </div>
        {errors.pin && (
          <p className={styles.textError}>{errors?.pin[0]?.message}</p>
        )}

        {withConfirmBtn && (
          <Button className={styles.buttonConfirmPins} type="submit">
            Confirm
          </Button>
        )}
      </div>
    </form>
  );
};

export default CodeInputField;
