import { Group, Box, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { getAccounts } from "../../../api/getAccounts";
import styles from "./styles.module.scss";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { PAGE_ROUTES } from "../../../pages/constants";
import EditableName from "./EditNamePopup";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { optionsTable, pageSize } from "../../../constants/constants";
import { maxWidthColumns } from "../../../styles/constants";
import AccountIcon from "../../../icons/menuItems/AccountIcon";

const DashBoardTable = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
  });
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const { userData, userDataIsLoading, errorUserData, userDataFetching } =
    useContext(AuthContext);

  const {
    data: accountsData,
    isLoading,
    isFetching,
    error: accountsError,
  } = useAuthQuery({
    queryKey: ["dataDashBoardTable", pagination.pageIndex, pageSize],
    queryFn: () =>
      getAccounts({
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    cacheTime: 2 * 60 * 1000,
    enabled: !!localStorage.getItem("token") && !!userData,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: ["dataDashBoardTable", pagination.pageIndex + 2, pageSize],
          queryFn: () =>
            getAccounts({
              page: pagination.pageIndex + 1,
              perPage: pageSize,
            }),
        });
      }
    },
  });

  useEffect(() => {
    if (accountsData) {
      setTableData(accountsData.data);
    }
  }, [accountsData]);

  if (isLoading || userDataIsLoading || userDataFetching || isFetching) {
    return <Skeleton className={styles.dashboardTableSkeleton} />;
  }

  if (accountsError || errorUserData) {
    return (
      <div>
        There was an error: {accountsError?.message || errorUserData?.message}
      </div>
    );
  }

  const columns = [
    {
      accessorKey: "iban",
      header: "IBAN",
      // size: 250,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box
          className={styles.dashboardItemLine}
          style={{ maxWidth: maxWidthColumns, overflow: "auto" }}
        >
          <Text size="sm" color="#AAAAAA" className="secondaryText">
            #{row.original.iban}
          </Text>
          <Group spacing="xs" mt={4}>
            <Text
              weight="bold"
              className="semiHedline"
              color="#232323"
              style={{ textOverflow: "ellipsis", overflow: "auto" }}
            >
              {row.original.name ? row.original.name : "Unnamed"}
            </Text>
            {userData?.role !== "Member" && (
              <EditableName
                account={row.original}
                setTableData={setTableData}
              />
            )}
          </Group>
        </Box>
      ),
    },
    {
      accessorKey: "notification",
      header: "NOTIFICATION",
      // size: 250,
      enableSorting: false,
      Cell: ({ row }) => {
        const balance = row.original.balance;
        const notificationText =
          balance < 1000
            ? "Please top-up your balance"
            : "There is no issue with your account";
        return (
          <Box>
            <Text size="sm" color="dimmed">
              Notification
            </Text>
            <Text size="sm" mt={4}>
              {notificationText}
            </Text>
          </Box>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "AVAILABLE BALANCE",
      // size: 350,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box>
          <Text size="sm" color="dimmed">
            Available Balance
          </Text>
          <Text size="sm" weight="bold" mt={4}>
            €{row.original.balance ? row.original.balance.toFixed(2) : 0}
          </Text>
        </Box>
      ),
    },
  ];

  return (
    <Box style={{ maxWidth: "100%" }}>
      <Group position="apart" p="md" style={{ padding: "0 0 26px 0" }}>
        <Text
          color="#232323"
          weight="bold"
          size="lg"
          className="H3"
          style={{ display: "flex", alignItems: "center", gap: "6px" }}
        >
          ACCOUNT'S BALANCES
          <AccountIcon height={22} />
        </Text>
        <Link
          className={clsx("buttons", styles.showMoreBtn)}
          to={PAGE_ROUTES.ACCOUNTS}
        >
          Show more
        </Link>
      </Group>
      <Table
        columns={columns}
        data={tableData}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={(updater) => {
          setPagination((prev) => {
            const newState =
              typeof updater === "function" ? updater(prev) : updater;
            return newState;
          });
        }}
        sx={{
          "& tbody tr td": {
            padding: "16px 0 !important",
            background: "white !important",
          },
          "& thead tr th": {
            padding: "16px 0 !important",
          },
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pageSize,
          },
        }}
        enableColumnActions={false}
        rowCount={accountsData?.meta?.total ?? 0}
        pageCount={
          accountsData?.meta?.last_page ??
          Math.ceil((accountsData?.meta?.total ?? 0) / pageSize)
        }
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableTopToolbar={false}
        enableDensityToggle={false}
        paginationDisplayMode={"pages"}
        mantinePaginationProps={{
          showRowsPerPage: false,
        }}
        mantineTableProps={{
          ...optionsTable,
        }}
      />
    </Box>
  );
};

export default DashBoardTable;
