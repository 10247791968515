import styles from "./styles.module.scss";
import { getAllNews } from "../../api/newsApi";
import NewsItem from "../../components/newsItem/NewsItem";
import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import { useEffect, useState } from "react";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";

const News = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["allNews", page, pageSize],
    queryFn: () => getAllNews({ page: page, perPage: pageSize }),
    keepPreviousData: true,
    staleTime: 3 * 60 * 1000,
    cacheTime: 3 * 60 * 1000,
    onSuccess: (newData) => {
      setNews((prevNews) => [...prevNews, ...newData]);
    },
  });

  useEffect(() => {
    if (data) {
      setLoading(false);
      setNews(data?.data || []);
    }
  }, [data]);

  // const news = data?.data || [];

  return (
    <div className={styles.newsPage}>
      <div className={styles.wrapperHeaderNews}>
        <h1>News</h1>
        <div>
          <DateFormatter
            fullLongFormat={true}
            live={true}
            className="titleDateFormatter"
          />
        </div>
      </div>

      {loading ? (
        <div className={styles.newsPageSceleton}>
          <LoaderSpinnerIcon />
        </div>
      ) : (
        <div className={styles.newsPageContainer}>
          {news.length ? <NewsItem data={news} /> : <p>No Data</p>}
        </div>
      )}
      {pageSize < data?.meta.total && (
        <p onClick={() => setPerPage(pageSize + 5)} className="primaryText">
          Show more...
        </p>
      )}
    </div>
  );
};

export default News;
